import * as React from 'react';
import { Link } from 'gatsby';
import Deer from '../components/deer/deer';

const NotFoundPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Deer height="80vh" width="80vw" />
      <Link to="/">
        <div
          style={{
            width: '80vw',
            backgroundColor: 'grey',
            height: ' 5vh',
            borderRadius: ' 16px',
            textAlign: 'center',
            lineHeight: ' 5vh',
            verticalAlign: ' middle',
            color: ' white',

            fontSize: '1.25rem',
            fontWeight: 500,
            fontStyle: 'normal',
            fontFamily: 'Poppins',
          }}
        >
          Seems lost, Go back
        </div>
      </Link>
    </div>
  );
};

export default NotFoundPage;
